import React, { useEffect } from "react";
import { useInViewport } from 'react-in-viewport';
import classNames from 'classnames'
import s from './Image.module.scss';

import {Image as ImageType} from "../../types";

export interface MediaQuery {
    minWidth: number,
    src: string
}

export interface ImageProps {
    image: ImageType;
    containerClass?: string;
    className?: string;
    listVideo?: string;
    mediaQueries?: Array<MediaQuery>;
    lazy?: boolean;
    isGif?: boolean;
    showImage?: boolean;
}

const Image: React.FC<ImageProps> = ({
    image,
    containerClass,
    className,
    isGif,
    listVideo,
    mediaQueries = [],
    showImage = false,
    lazy = true,
}) => {
    const myRef = React.useRef();
    const {
      enterCount,
    } = useInViewport(
      myRef,
    );

    if(isGif) {
        image = {
            src: listVideo,
            alt: ""
        }
    }

    const classes = classNames(s.Image, {
        [s.ImageInView]: showImage || enterCount > 0
    })

    return (
        <picture ref={myRef} className={containerClass + ' ' + classes} aria-hidden="true">
            {!isGif && mediaQueries.length > 0 &&
                mediaQueries.map((mq, index) => (
                    <source
                        media={`(min-width: ${mq.minWidth}px)`}
                        srcSet={mq.src}
                        key={index}
                    />
                ))}
            <img
                className={className}
                {...image}
                loading={lazy ? "lazy" : "eager"}
            />
        </picture>
    );
}

export default Image;
